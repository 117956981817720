.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-primary);
}

.mdc-text-field.title > input {
  font-size: 28px !important;
  color: #3c4043 !important;
}

.kmdc_compact {
  height: 36px;
}

.kmdc_compact > input {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
.kmdc_compact > .mdc-select__anchor {
  height: 36px !important;
}
.kmdc_compact .mdc-select__dropdown-icon {
  top: 8px !important;
}
.kmdc_compact .rmwc-icon {
  top: 6px !important;
}
.kmdc_compact .mdc-select__selected-text {
  padding-top: 6px !important;
}
