.mdc-select--focused:not(.mdc-select--disabled) .mdc-floating-label {
  color: var(--mdc-theme-primary) !important;
}

.mdc-select--focused:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  background: url('data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%234990e2%22%20fill-rule%3D%22evenodd%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E')
    no-repeat center;
}

.rmwc-select__native-control {
  /* Fix for safari: https://stackoverflow.com/a/17469167/5059407 */
  -webkit-appearance: none !important;
}
