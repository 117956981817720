.kmdc_tabBar_compact .mdc-tab {
  height: 36px;
  padding: 0 8px;
  flex: ;
}
.kmdc_tabBar_compact .mdc-tab__ripple {
  border-radius: 4px 4px 0 0;
}
.kmdc_tabBar_compact .mdc-tab-indicator__content--underline {
  border-radius: 3px 3px 0 0;
  border-top-width: 3px;
}

.kmdc_tabBar_compact .mdc-tab__text-label {
  text-transform: lowercase;
  /* font-weight: 600; */
}
.kmdc_tabBar_compact .mdc-tab__text-label::first-letter {
  text-transform: uppercase;
}
