.Range .DayPicker-Day {
  padding: 0;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto';
  font-size: 13px;
  width: 21px;
  height: 21px;
  padding: 5px !important;
}
.DayPicker-Day--disabled,
.DayPicker-Day--outside {
  color: #dce0e0;
  cursor: default;
  background-color: transparent;
}

Range .DayPicker-Caption {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.Range .DayPicker-wrapper {
  width: 580px !important;
}

.DayPicker-Month {
  width: 255px;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4990e2;
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.range-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 350px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.14);

  transition: all 0.15s ease-in-out;
}
.range-wrapper:hover {
  cursor: pointer;
  border-bottom-color: #4990e2;
  border-bottom-width: 2px;
  height: 49px;
}
.range-wrapper > div {
  color: rgba(0, 0, 0, 0.77);
  font-size: 18px;
}
