/** Own global styles **/

.clickable:hover {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5 {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  margin: 0;
}
div :focus {
  outline: none;
}

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Lucida Grande', 'Ubuntu', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}
#root > div {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: #2e8fff;
}

.ReactModalPortal {
  z-index: 1000;
  position: relative;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

Pseudo ::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}
Pseudo ::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #dadce0;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  min-height: 40px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
