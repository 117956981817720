.mdc-drawer .mdc-list-item--activated {
  color: var(--mdc-theme-primary);
}

.rmwc-collapsible-list__children .mdc-list-item {
  padding-left: 1.5rem !important;
}

.mdc-drawer .mdc-list-item {
  border-radius: 8px !important;
  height: calc(40px - 2 * 4px);
  color: #474747;
}

.mdc-drawer .mdc-list-item--activated {
  color: var(--mdc-theme-primary) !important;
}
