.DayPicker-Day {
  padding: 0;
  border: none;
}
.DayPicker-Day--disabled,
.DayPicker-Day--outside {
  color: #dce0e0;
  cursor: default;
  background-color: transparent;
}
.DayPicker-Weekday > abbr {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  width: 35px;
}

.DayPicker-Caption > div {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
  text-decoration: inherit;
  text-transform: inherit;
  color: #474747;
}

.DayPicker-wrapper {
  box-sizing: border-box;
}

.DayPicker-Week {
  height: 30px;
}

.DayPicker-Month {
  margin: 0;
  margin-top: 1rem;
}
